var menuFilter = $('[data-menu-trocar-active="true"]');
menuFilter.each(function(){
	var isso = $(this);
	isso.find('a').click(function(){
		isso.find('a').removeClass('active');
		if($(this).hasClass('active')){
			$(this).removeClass('active');
		}else{
			$(this).addClass('active');
		}
	});
});