(function(){
	var videoIncrivel 	= $('.video-incrivel');
	videoIncrivel.click(function(){
		var htmlPronto 	= $(this).find('template').html();
		var bgVideo	   	= $(this).data('video');
		var urlUm 		= bgVideo.substr(bgVideo.indexOf('watch?v=') + 8);
		var urlOriginal = 'https://www.youtube.com/embed/';
		bgVideo 		= urlOriginal + urlUm;
		bgVideo 		= bgVideo + '?autoplay=1';
		$(this).html(htmlPronto);
		$(this).addClass('ativado');
		$(this).find('iframe').attr('src',bgVideo);
		$(this).attr('style','');
	});
})();